figure img {
  height: auto;
}

.spacer_regular + .spacer_regular,
.spacer_regular + .spacer_gallery,
.spacer_gallery + .spacer_regular {
  margin-top: var(--space_l);
}

.spacer_gallery + .spacer_gallery {
  margin-top: var(--space_s);
}

@media screen and (min-width: 1080px) {
  .spacer_gallery:has(figcaption) + .spacer_gallery {
    margin-top: calc(var(--space_s) - var(--font_base));
  }
}

.spacer_offset {
  margin-top: var(--space_m);
  margin-bottom: var(--space_m);
}

.spacer_offset + .spacer_offset {
  margin-top: 0;
}

.spacer_large {
  margin-top: var(--space_xl);
  margin-bottom: var(--space_xl);
}

.spacer_footer {
  margin-top: calc(var(--space_m));
  margin-bottom: var(--space_m);
}

@media screen and (min-width: 1080px) {
  .spacer_gallery:has(figcaption) + .spacer_gallery {
    margin-top: calc(var(--space_m) - var(--font_base));
  }

  .spacer_gallery + .spacer_gallery {
    margin-top: var(--space_m);
  }

  .spacer_offset {
    margin-bottom: var(--space_xl);
  }

  .spacer_offset:has(.image_drip) {
    margin-bottom: var(--space_m);
  }

  .spacer_regular + .spacer_regular {
    margin-top: var(--space_xxl);
  }

  .spacer_footer {
    margin-top: calc(var(--space_m) * 5);
    margin-bottom: var(--space_xl);
  }
}

.flexy_beast {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--space_m);
  justify-content: center;
}

.flexy_beast > *:not(h2) {
  flex: 1 1 auto;
}

.flex_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

@media screen and (min-width: 1080px) {
  .flexy_beast > *:not(h2) {
    flex-basis: min-content;
  }

  .flex_row {
    justify-content: space-between;
  }
}

.gallery_item_crop figure,
.gallery_item_crop picture {
  height: 100%;
  overflow: hidden;
}

.gallery_item_crop figure,
.gallery_item_crop picture {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.gallery_item_crop figure img,
.gallery_item_crop picture img {
  object-fit: cover;
  height: 100%;
}

/* Animation */

@keyframes boing_right {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(calc(var(--space_xs) / 2));
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes boing_left {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(calc(-1 * calc(var(--space_xs) / 2)));
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes scaledown {
  0% {
    opacity: 0;
    transform: scaleY(0);
    transform-origin: 50% 0%;
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: 50% 0%;
  }
}

@keyframes scalealong {
  0% {
    opacity: 0;
    transform: scaleX(0);
    transform-origin: 0% 100%;
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: 0% 100%;
  }
}

.opacity_0 {
  opacity: 0;
}

.opacity_100 {
  opacity: 1;
}

.fadein {
  animation-name: fadein;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}

.scalepseudo::before {
  opacity: 0;
  animation: scaledown 5s forwards;
}

.delay_short {
  animation-delay: 100ms;
}

.delay_med {
  animation-delay: 200ms;
}

.delay_long {
  animation-delay: 300ms;
}

@media screen and (max-width: 440px) or (prefers-reduced-motion) {
  .fadein {
    animation: none;
    opacity: 1;
  }

  .delay_long {
    animation-delay: 0s;
  }
}
