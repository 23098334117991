@media screen and (min-width: 1080px) {
  .grid_12 {
    display: grid;
    grid-template-columns: repeat(12, minmax(var(--grid_width), 1fr));
    gap: var(--space_s);
    align-items: center;
  }

  body > header .grid_12 {
    align-items: baseline;
  }

  .gallery.grid_12 {
    row-gap: var(--space_m);
  }

  .grid_12.featured > h3 {
    grid-column: 1/ -1;
  }

  .grid_12.featured {
  }

  .list.grid_12 {
    row-gap: var(--space_l);
  }

  .grid_start {
    grid-column-start: 1;
  }

  .grid_start_2 {
    grid-column-start: 2;
  }

  .grid_start_3 {
    grid-column-start: 3;
  }

  .grid_start_4 {
    grid-column-start: 4;
  }

  .grid_start_5 {
    grid-column-start: 5;
  }

  .grid_start_6 {
    grid-column-start: 6;
  }

  .grid_start_7 {
    grid-column-start: 7;
  }

  .grid_start_8 {
    grid-column-start: 8;
  }

  .grid_start_9 {
    grid-column-start: 9;
  }

  .grid_start_10 {
    grid-column-start: 10;
  }

  .grid_end_1 {
    grid-column-end: 1;
  }

  .grid_end_3 {
    grid-column-end: 3;
  }

  .grid_end_5 {
    grid-column-end: 5;
  }

  .grid_end_4 {
    grid-column-end: 4;
  }

  .grid_end_6 {
    grid-column-end: 6;
  }

  .grid_end_7 {
    grid-column-end: 7;
  }

  .grid_end_8 {
    grid-column-end: 8;
  }

  .grid_end_9 {
    grid-column-end: 9;
  }

  .grid_end_10 {
    grid-column-end: 10;
  }

  .grid_end_11 {
    grid-column-end: 11;
  }

  .grid_end_12 {
    grid-column-end: 12;
  }

  .grid_end {
    grid-column-end: -1;
  }

  .grid_order_1 {
    order: 1;
  }

  .grid_order_2 {
    order: 2;
  }

  .gallery.grid_12,
  main .grid_12:first-child {
    align-items: flex-start;
  }

  .item.grid_12 + .item.grid_12 {
    margin-top: var(--space_m);
  }

  .grid_span_1 {
    grid-column: span 1;
  }

  .grid_span_3 {
    grid-column: span 3;
  }

  .grid_span_4 {
    grid-column: span 4;
  }

  .grid_span_5 {
    grid-column: span 5;
  }

  .grid_span_6 {
    grid-column: span 6;
  }

  .grid_span_7 {
    grid-column: span 7;
  }

  .grid_span_8 {
    grid-column: span 8;
  }

  .grid_span_10 {
    grid-column: 2 / 12;
  }

  .grid_span_all {
    grid-column: 1 / -1;
  }

  .grid_row_2 {
    grid-row: 2;
  }

  div:not(.contact) form fieldset:first-child {
    display: grid;
    grid-template-areas:
      'fname lname .'
      'email tel company'
      'message message .';
    gap: var(--space_m);
  }

  .contact form fieldset:first-child {
    display: grid;
    grid-template-areas:
      'fname lname .'
      'email tel company'
      'message message message';
    gap: var(--space_m);
  }

  form {
    flex-direction: column;
  }

  .fieldgroup + .fieldgroup {
    margin-top: 0;
  }
  .area_fname {
    grid-area: fname;
  }

  .area_lname {
    grid-area: lname;
  }

  .area_email {
    grid-area: email;
  }

  .area_company {
    grid-area: company;
  }

    .area_tel {
    grid-area: tel;
  }

    .area_message {
    grid-area: message;
  }
}
