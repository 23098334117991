@media screen and (max-width: 1079px) {
  body > header .branding,
  header nav {
    box-shadow: var(--shadow);
  }
}

body > header {
  background-color: var(--white);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0.9) 95%,
    rgba(255, 255, 255, 1) 100%
  );
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.branding img {
  width: 100%;
  max-width: 355px;
}

label,
header nav ul {
  font-weight: var(--fw_bold);
}

input {
  border: none;
  border-bottom: 1px solid var(--black);
  transition: outline 0.25s ease-out;
  outline-color: transparent;
  outline-offset: 3px;
  outline-width: 2px;
  outline-style: dotted;
}

input::placeholder,
textarea::placeholder {
  visibility: hidden;
}

input:focus,
input:focus-visible,
textarea:focus,
.error {
  outline-color: var(--grey);
  outline-offset: 3px;
  outline-width: 2px;
  outline-style: dotted;
}

form button {
  color: var(--white);
  font-weight: var(--fw_bold);
  border-radius: 2px;
  border: 1px solid var(--black);
  background-color: var(--black);
  display: flex;
  padding: var(--space_s) var(--space_m);
  gap: var(--space_xs);
}

form button:hover {
  cursor: pointer;
}

form button:disabled {
  cursor: unset;
  background-color: var(--darkgrey);
  border-color: var(--darkgrey);
  opacity: .5;
}

.f-req {
  padding-left: .25ch;
  font-weight: var(--fw_regular);
}

.f-error,
.uniform-errors,
#message {
  background-color: var(--black);
  border-radius: 2px;
  padding: var(--space_xs) var(--space_s);
  color: var(--white);

  margin-top: var(--space_xs);
}

.buzz {
  position: absolute;
  left: -9999px;
}

.pagination .next {
  text-align: right;
}

.pagination > div > a {
  display: block;
  margin-bottom: var(--space_xs);
}

.toolbar > ul {
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--space_m);
  row-gap: var(--space_s);
}

.toolbar .category li:first-child {
  flex-basis: 100%;
}

.toolbar ul + ul {
  margin-top: var(--space_m);
}

@media screen and (min-width: 1080px) {
  .toolbar {
    display: flex;
    justify-content: space-between;
    margin-top: var(--space_l);
  }
  .toolbar > ul {
    flex-wrap: nowrap;
  }

  .toolbar .category li:first-child {
    flex-basis: unset;
  }

  .toolbar ul + ul {
    margin-top: unset;
  }
}

.toolbar .category li:not(:first-child) {
  font-weight: var(--fw_bold);
}

.layout .icon::after {
  width: 1ch;
  height: 1ch;
  margin-left: 1ch;
  display: inline-flex;
  align-items: center;
  top: 0.25em;
  position: relative;
  transition: transform 0.3s ease-out;
}

.icon_grid::after {
  content: url("/assets/images/icon_grid.svg");
}

.icon_list::after {
  content: url("/assets/images/icon_list.svg");
}

.layout .icon:hover::after {
  transform: scale(1.15);
}

.previous > a::before {
  content: url("/assets/images/icon_arrow_left.svg");
  margin-right: 1ch;
}

.next > a::after {
  content: url("/assets/images/icon_arrow_right.svg");
  margin-left: 1ch;
}

.pagination .next a::after,
.pagination .previous a::before {
  display: inline-block;
  animation-duration: 1.25s;
  animation-iteration-count: infinite;
}

.pagination .previous a:hover::before {
  animation-name: boing_left;
  animation-timing-function: ease-out;
}

.pagination .next a:hover::after {
  animation-name: boing_right;
  animation-timing-function: ease-out;
}

.navigation-wrapper {
  position: relative;
}

.arrow {
  width: 42px;
  height: 24px;
  position: absolute;
  /* transform: translateY(100%);
    -webkit-transform: translateY(-50%); */
  cursor: pointer;
  background-color: var(--white);
  transition: opacity 0.3s ease-out;
}

.arrow--left {
  left: var(--space_m);
  mask-image: url("/assets/images/icon_arrow_left.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
}

.arrow--right {
  right: var(--space_m);
  mask-image: url("/assets/images/icon_arrow_right.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
}

.arrow.arrow--disabled {
  opacity: 0.5;
}

.pagelist ul {
  display: flex;
  list-style: none;
  margin: 0;
}

.pagelist ul li + li {
  margin-left: var(--space_s);
}

.block_coloured {
  border-radius: var(--radius);
  background-color: var(--grey);
}

.block_coloured {
  width: 100%;
}

.block_coloured > * {
  max-width: 636px;
}

.image_drip {
  mask-image: url("/assets/images/drip_mask.svg");
  mask-repeat: no-repeat;
  mask-position: center 0;
  mask-size: 272px, auto, contain;
}

@media screen and (max-width: 1079px) {
  .image_drip {
    display: none;
  }
}

#dotty {
  display: flex;
  position: relative;
  width: 50px;
  height: 50px;
}

#dotty > svg {
  fill: var(--black);
}

#dot {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
}

#drip {
  position: absolute;
  width: 12px;
  left: 25px;
  top: 22px;
  transform: translateY(0);
}

.gallery_item:has(iframe) {
  width: 100%;
}

.gallery_item iframe {
  border: none;
  width: 100%;
  aspect-ratio: 16 / 9;
  height: auto;
}
