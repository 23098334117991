body {
  font-family: var(--font);
  font-size: var(--font_base);
  font-style: normal;
  font-weight: var(--fw_regular);
  line-height: var(--ratio);
}

p + p {
  margin-top: 1em;
}

.text_small,
figcaption {
  font-size: var(--font_base);
  font-style: normal;
  font-weight: var(--fw_regular);
  line-height: var(--ratio);
}

@media screen and (max-width: 440px) {
  .text_small,
  .text_small h3,
  figcaption {
    font-size: 13.859px;
  }
}

.text_tiny {
  font-size: 13.859px;
  font-style: normal;
  font-weight: var(--fw_regular);
  line-height: var(--ratio);
}

strong,
dt {
  font-weight: var(--fw_bold);
}

h1,
.h1 {
  font-size: var(--font_h1);
  font-style: normal;
  font-weight: var(--fw_bold);
  line-height: var(--ratio);
}

@media screen and (max-width: 1079px) {
  h1,
  .h1 {
    font-size: var(--font_h2);
  }
}

h1 + * {
  margin-top: 1em;
}

.page_inner header > h1 {
  margin-bottom: calc(var(--font_h1) - var(--space_s));
}

h2,
.h2 {
  font-size: 27.779px;
  font-style: normal;
  font-weight: var(--fw_bold);
  line-height: var(--ratio);
}

.h2:has(h1) h1 {
  font-size: 27.779px;
}

h3,
.h3,
header h1 ~ p,
.prose > p:first-child {
  font-size: 22px;
  font-style: normal;
  font-weight: var(--fw_semibold);
  line-height: var(--ratio);
}

@media screen and (max-width: 440px) {
  header h1 ~ p,
  .prose > p:first-child {
    font-size: var(--font_base);
  }
}

h4,
.h4 {
  font-size: var(--font_base);
  font-style: normal;
  font-weight: var(--fw_bold);
  line-height: var(--ratio);
}

p.h2,
p.h3,
.h2 > p {
  font-weight: var(--fw_semibold);
}

header h1 ~ p,
h1 + .prose > p:first-child,
header + .prose p:first-child {
  font-weight: var(--fw_regular);
}

.keen-slider__slide h4 {
  color: var(--white);
}

blockquote p {
  font-weight: var(--fw_bold);
  font-style: italic;
}

blockquote cite {
  font-size: 13.859px;
  font-style: normal;
  font-weight: var(--fw_semibold);
  line-height: var(--ratio);
  display: inline-block;
}

blockquote p + cite {
  margin-top: 1em;
}

@media screen and (min-width: 1080px) {
  .hero blockquote p {
    font-size: 27.779px;
  }

  .hero blockquote cite {
    font-size: var(--font_base);
  }
}

ul[role='list'] {
  padding: 0;
  margin: 0;
  list-style: none;
}

.prose > * + * {
  margin-top: 1em;
}
