:root {
--black: rgba(0, 0, 0, 1);
--white: rgba(255, 255, 255);
--grey: rgb(229, 229, 229);
--darkgrey: rgb(120, 120, 120);

--font: "aktiv-grotesk", Helvetica, Arial;
--fw_regular: 400;
--fw_semibold: 600;
--fw_bold: 700;
--fw_black: 900;
--ratio: 1.41;

--font_base: 17.461px;
--font_h1: 35px;
--font_h2: 27.779px;

--shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.14);
--radius: 10px;

--grid_width: 69px;
--page_small: 440px;
--page_limit: 1224px;
--grid_min_width: 1080px;

--space_xs: 9px;
--space_s: 18px;
--space_m: 36px;
--space_l: 54px;
--space_xl: 72px;
--space_xxl: 108px;
--space_xxxl: 144px;
}

header nav ul li a {
    text-decoration:none;
}

a, a:visited, a:link, a:active  {
    color: var(--black);
    text-decoration-color: transparent;
    text-decoration: underline;
    text-underline-offset: 2px;
    transition: text-decoration-color .6s ease-out;
}

a:hover, a.selected{
    text-decoration: underline;
    text-decoration-color: var(--black);
    transition: text-decoration-color .6s ease-in;

}

