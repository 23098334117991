header nav ul li a,
.pagelist ul li a.selected,
h4 a,
a[href^='tel:'] {
  text-decoration: none;
}

figcaption {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1225px) {
  figcaption {
    margin: 0 var(--space_s) 0 var(--space_s);
  }
}

@media screen and (max-width: 1079) {
  figcaption {
    margin: var(--space_xs) 5vw 0 5vw;
  }
}

figcaption .link a:link {
  text-decoration: underline;
  text-decoration-color: var(--black);
  display: flex;
  gap: var(--space_xs);
}

figcaption .link a::after {
  content: '';
  background-image: url('/assets/images/icon_link.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 1em;
  height: 1em;
  display: inline-flex;
  align-self: center;
}

.slider h4 {
  opacity: 0;
}

.slider h4 > a {
  color: var(--white);
}

.content a,
.pagination a,
body > footer * div:not(.contact) ul[role='list'] li a,
.selected {
  text-decoration-color: var(--black);
}

.flexy_beast > h2,
.flexy_beast > h3,
.featured > h3 {
  flex-basis: 100%;
  text-align: center;
}

.flexy_beast.clients {
  align-items: center;
}

.empty {
  align-self: flex-start;
  height: 100px;
}

/* Mobile overrides */
@media screen and (max-width: 1079px) {
  .flexy_beast.clients {
    gap: var(--space_s);
    justify-content: space-between;
  }
  .flexy_beast.clients .client_logo {
    flex: 1 0 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .flexy_beast.clients .client_logo img {
    max-width: 132px;
  }

  .empty {
    display: none;
  }

  footer .flex_row {
    justify-content: space-between;
  }

  .page_content:has(> div:first-child > figure) > div:first-child {
    order: 2;
  }

  .featured h3 {
    margin-bottom: 1em;
  }

  .featured .card + .card {
    margin-top: var(--space_m);
  }

  main > .page_content:has(header > h1:only-child):has(+ .page_inner.gallery) {
    margin-bottom: 0;
  }
}
