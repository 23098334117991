body {
  background-color: var(--white);
  color: var(--black);
}

.hero,
.hero figure,
.hero figure img,
.hero picture,
.hero picture img {
  width: 100%;
}

body > header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.page_inner {
  width: 100%;
  max-width: var(--page_limit);
}

@media screen and (max-width: 1079px) {
  .page_inner:not(.width_full),
  .page_inner:has(> header:first-child),
  .grid_span_6:has(> .prose) {
    max-width: 80vw;
  }
}

@media screen and (min-width: 1080px) and (max-width: 1223px) {
  .page_inner:not(.width_full) {
    padding-left: 2vw;
    padding-right: 2vw;
  }
}

.block_coloured {
  padding: var(--space_s);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.gallery_item > .block_coloured.hero {
  align-self: center;
}

@media screen and (max-width: 1079px) {
  .gallery_item > .block_coloured.hero {
    margin: 0 10vw;
  }
}

.gallery_item:has(> .block_coloured.hero:first-child) {
  display: flex;
}

@media screen and (max-width: 1079px) {
  header + aside,
  div + aside,
  .list + .list,
  .projects:not(.list) .gallery_item + .gallery_item {
    margin-top: var(--space_s);
  }
}

@media screen and (min-width: 1080px) {
  .block_coloured {
    padding: var(--space_m);
    align-self: stretch;
    align-items: center;
  }

  blockquote p + cite {
    padding-top: var(--space_s);
  }
}

body > header .branding {
  margin-top: var(--space_m);
  padding: var(--space_m);
}

body > header .branding,
header nav {
  display: flex;
  justify-content: center;
}

body > header nav {
  padding: var(--space_s) var(--space_m);
}

@media screen and (min-width: 1080px) {
  body > header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0;
    padding-bottom: var(--space_m);
    margin-top: var(--space_m);
    margin-bottom: var(--space_m);
  }
  body > header .branding,
  header nav,
  body > header nav {
    padding: 0;
    justify-content: flex-start;
  }
}

body > header nav ul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: var(--space_s);
}

@media screen and (min-width: 1080px) {
  body > header nav ul {
    width: 100%;
    justify-content: space-between;
  }
  body > header nav ul li + li {
    margin-left: 0;
  }
}

main > header {
  display: flex;
  flex-direction: column;
}

main > header > img {
  margin-top: var(--space_m);
}

main,
body > footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

main > *,
body > footer > * {
  width: 100%;
}

form {
  margin-top: var(--space_m);
}

form fieldset {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  border: none;
  margin: 0;
  padding: 0;
}

form fieldset > * {
  flex: 0 1 100%;
}

@media screen and (min-width: 1080px) {
  form {
    display: flex;
  }
}

.buttongroup {
  display: flex;
  flex-direction: column;
}

.buttongroup button {
  align-self: flex-end;
}

fieldset + fieldset,
.fieldgroup + .fieldgroup {
  margin-top: var(--space_m);
}

label + input,
label + textarea {
  margin-top: var(--space_s);
}

.fieldgroup > * {
  width: 100%;
}

#slider {
  z-index: 0;
  width: 100%;
}

.keen-slider__slide {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.keen-slider__slide img {
  width: 100%;
}

.keen-slider__slide h4 {
  position: absolute;
  bottom: var(--space_m);
}

.arrow {
  bottom: var(--space_m);
}
.card img + figcaption {
  margin-top: var(--space_xs);
}

dl {
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: var(--space_m);
  row-gap: var(--space_s);
}

.gallery,
.page_content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: var(--space_m);
}

@media screen and (max-width: 1079px) {
  .gallery,
  .page_content {
    flex-wrap: wrap;
  }

  .page_content:first-child {
    row-gap: unset;
  }
}

.gallery_item figure + figure,
.gallery_item picture + picture {
  margin-top: var(--space_s);
}

.gallery_item_70 {
  flex-basis: 70%;
}

.gallery_item_60 {
  flex-basis: calc(60% - calc(var(--space_m) / 2));
}

.gallery_item_50 {
  flex-basis: calc(50% - var(--space_m));
}

.gallery_item_40 {
  flex-basis: calc(40% - calc(var(--space_m) / 2));
}

.gallery_item_30 {
  flex-basis: 30%;
}

@media screen and (max-width: 667px) {
  .gallery_item_40,
  .gallery_item_50,
  .gallery_item_60 {
    flex-basis: unset;
  }
}

@media screen and (max-width: 1079px) {
  .gallery {
    gap: var(--space_s);
    justify-content: center;
  }
}

.item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--space_s);
}

@media screen and (max-width: 1079px) {
  .item + .item {
    margin-top: var(--space_m);
  }
}

@media screen and (min-width: 400px) and (max-width: 1079px) {
  .item_text {
    max-width: 50%;
  }
}

.item_text {
  max-width: 476px;
}

@media screen and (min-width: 1080px) {
  .item figure,
  .item picture {
    max-width: 272px;
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
}

.pagination:not(:has(.previous)) {
  flex-direction: row-reverse;
}

main + footer {
  margin-top: var(--space_l);
}

body > footer > *:last-child {
  width: 100%;
  justify-content: space-between;
}

@media screen and (max-width: 1079px) {
  body > footer > * + * {
    margin-top: var(--space_m);
  }
  body > footer > *:first-child {
    margin-top: var(--space_m);
  }

  .dot {
    margin-bottom: var(--space_l);
  }
}

.dot {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-basis: 100%;
}

@media screen and (min-width: 1080px) {
  .dot {
    order: 3;
    align-self: flex-start;
    justify-content: flex-end;
    flex-basis: auto;
    flex-shrink: ;
    flex-grow: 1;
  }

  .contact,
  .social {
    flex-basis: 33%;
  }
}
